import { faMarkdown } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressBook,
  faAddressCard,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faArrowsAlt,
  faAsterisk,
  faAt,
  faBackspace,
  faBan,
  faBars,
  faBell,
  faBirthdayCake,
  faBox,
  faBoxOpen,
  faBoxes,
  faBrainCircuit,
  faBriefcase,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseyePointer,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCalendarXmark,
  faCaretDown,
  faCartPlus,
  faCartXmark,
  faCertificate,
  faChartSimple,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleExclamation,
  faCircleQuestion,
  faCircleThreeQuarters,
  faCircleUser,
  faClipboardList,
  faClock,
  faClone,
  faCloudUploadAlt,
  faCog,
  faCoins,
  faColumns,
  faCopy,
  faCopyright,
  faCreditCard,
  faDatabase,
  faDirections,
  faDollarSign,
  faDolly,
  faDollyFlatbed,
  faDotCircle,
  faDrawPolygon,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileArrowDown,
  faFileDownload,
  faFileImage,
  faFileInvoice,
  faFilePlus,
  faFileSpreadsheet,
  faFileUpload,
  faFill,
  faFilter,
  faFilterList,
  faFlag,
  faFloppyDiskCircleArrowRight,
  faFolder,
  faFolderOpen,
  faFolderUser,
  faGem,
  faGift,
  faGlobe,
  faGlobeAfrica,
  faGrid2Plus,
  faHandHoldingDollar,
  faHashtag,
  faHeart,
  faHistory,
  faHome,
  faHourglass,
  faIdCard,
  faIdCardAlt,
  faImagePolaroid,
  faImageSlash,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLink,
  faLinkSlash,
  faList,
  faLoader,
  faLocationArrow,
  faLock,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapPin,
  faMars,
  faMemo,
  faMicrochipAi,
  faMinus,
  faMinusCircle,
  faMoneyBill,
  faMoneyBillWave,
  faNote,
  faPaintBrush,
  faPalette,
  faPallet,
  faPaperPlane,
  faPaperPlaneTop,
  faPause,
  faPen,
  faPenFancy,
  faPencilRuler,
  faPercent,
  faPersonRunning,
  faPhone,
  faPhotoVideo,
  faPlay,
  faPlus,
  faPlusCircle,
  faPlusMinus,
  faPrint,
  faReceipt,
  faRecycle,
  faRedo,
  faRegistered,
  faRotate,
  faRotateExclamation,
  faRss,
  faSave,
  faSearch,
  faShelves,
  faShieldAlt,
  faShop,
  faShoppingCart,
  faSignOutAlt,
  faSignature,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAlt,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSortNumericDown,
  faSortNumericUp,
  faSortNumericUpAlt,
  faSpinner,
  faStar,
  faStore,
  faStream,
  faStroopwafel,
  faSwatchbook,
  faSync,
  faTableList,
  faTag,
  faTags,
  faTasks,
  faThumbTack,
  faTicket,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTruck,
  faTruckArrowRight,
  faTruckFast,
  faTruckPlane,
  faUndo,
  faUpload,
  faUser,
  faUserCog,
  faUserPen,
  faUserShield,
  faUsers,
  faVectorSquare,
  faVenus,
  faVideo,
  faVrCardboard,
  faWallet,
  faWarehouse,
  faX,
  faCode as farCode,
  faCommentAlt as farCommentAlt,
  faMoneyBillAlt as farMoneyBillAlt,
  faPlusSquare as farPlusSquare,
  faQuestion as farQuestion,
} from '@fortawesome/pro-regular-svg-icons';
import { faHeart as fasHeart, faStar as fasStar } from '@fortawesome/pro-solid-svg-icons';

export const APP_ICONS = [
  faPaperPlaneTop,
  faAngleDown,
  faBrainCircuit,
  faMicrochipAi,
  faShop,
  faAddressBook,
  faAddressCard,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faFileArrowDown,
  faFilter,
  faFilterList,
  faAngleLeft,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUpRightFromSquare,
  faAsterisk,
  faAt,
  faBackspace,
  faBan,
  faBars,
  faBell,
  faBirthdayCake,
  faBox,
  faBoxes,
  faBoxOpen,
  faBriefcase,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseyePointer,
  faCalendar,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarDay,
  faCalendarXmark,
  faCaretDown,
  faCartPlus,
  faCartXmark,
  faCertificate,
  faChartSimple,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircle,
  faCircleExclamation,
  faCircleQuestion,
  faCircleThreeQuarters,
  faCircleUser,
  faClipboardList,
  faTableList,
  faClock,
  faClone,
  faCloudUploadAlt,
  faCog,
  faCoins,
  faColumns,
  faCopy,
  faCopyright,
  faCreditCard,
  faDirections,
  faDollarSign,
  faDolly,
  faDollyFlatbed,
  faDotCircle,
  faDrawPolygon,
  faDatabase,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePlus,
  faFileImage,
  faImageSlash,
  faFileInvoice,
  faFill,
  faFlag,
  faFloppyDiskCircleArrowRight,
  faFolderUser,
  faGem,
  faGift,
  faGlobe,
  faGlobeAfrica,
  faHandHoldingDollar,
  faHashtag,
  faHeart,
  faHistory,
  fasHeart,
  faHome,
  faHourglass,
  faIdCard,
  faIdCardAlt,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLink,
  faLinkSlash,
  faList,
  faLoader,
  faLocationArrow,
  faLock,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapPin,
  faMarkdown,
  faMars,
  faMemo,
  faMinus,
  faMinusCircle,
  faMoneyBill,
  faMoneyBillWave,
  faNote,
  faPaintBrush,
  faPalette,
  faPallet,
  faPaperPlane,
  faPause,
  faPen,
  faPencilRuler,
  faPenFancy,
  faPersonRunning,
  faPhone,
  faPhotoVideo,
  faVideo,
  faPlay,
  faPlus,
  faPlusCircle,
  faPlusMinus,
  faPrint,
  farCode,
  farCommentAlt,
  faReceipt,
  faRecycle,
  faRedo,
  faRegistered,
  farMoneyBillAlt,
  farPlusSquare,
  farQuestion,
  faRss,
  faSave,
  faSearch,
  faShelves,
  faShieldAlt,
  faShoppingCart,
  faSignature,
  faSignOutAlt,
  faSort,
  faSortAlt,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSortNumericDown,
  faSortNumericUp,
  faSortNumericUpAlt,
  faSpinner,
  fasStar,
  faStar,
  faStore,
  faStream,
  faStroopwafel,
  faSwatchbook,
  faSync,
  faRotate,
  faRotateExclamation,
  faTag,
  faTags,
  faTasks,
  faThumbTack,
  faTicketAlt,
  faTicket,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTruck,
  faTruckArrowRight,
  faTruckFast,
  faUndo,
  faUser,
  faUserCog,
  faUserPen,
  faUsers,
  faUserShield,
  faVectorSquare,
  faVenus,
  faVrCardboard,
  faWallet,
  faWarehouse,
  faX,
  faLayerPlus,
  faLayerMinus,
  faTruckPlane,
  faFileUpload,
  faUpload,
  faFileDownload,
  faFileSpreadsheet,
  faPercent,
  faFolder,
  faFolderOpen,
  faImagePolaroid,
  faGrid2Plus,
];
